.merchant-selector {
    position: relative;
    /* width: 300px; */
}

.merchant-dropdown {
    list-style: none;
    margin: 0;
    padding: 0;
    background: white;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    position: absolute;
    width: 100%;
    z-index: 10;
}

.merchant-dropdown li {
    display: flex;
    align-items: center;
    padding: 10px;
    cursor: pointer;
}

.merchant-dropdown li:hover {
    background: #f0f0f0;
}

.merchant-logo {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 50%;
}

.selected-merchant {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.selected-merchant-logo {
    width: 40px;
    height: 40px;
    margin-right: 10px;
    border-radius: 50%;
}