nav {
    background-color: #1976D2;
}

.Sidenav {
    padding-left: 260px;
    /* height:100vh; */
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.padding15 {
    padding: 15px;
}

.width-100 {
    width: 100px;
}

.justify-content-flex-end {
    justify-content: flex-end !important;
}

.leftMargin10 {
    margin: 0 10px 0 0;
}

.account-group-label-div {
    padding: 15px 20px 0 20px !important;
}

.floatRight {
    float: right;
}

.word-break-all {
    word-break: break-all;
}

.future-label-account {
    display: flex !important;
    justify-content: flex-end;
    font-size: 0.8rem !important;
}

.future-label-account>p {
    margin: 0 !important;
    background-color: #ffeb3b !important;
    color: #303030 !important;
    padding: 0 1px;
}

.amount-symbol {
    font-weight: bold;
}

.progress-bar-control {
    width: inherit !important;
}

.min-width-70 {
    min-width: 70px !important;
}

.line-height-1point5 {
    line-height: 1.5 !important;
    font-size: 1.4rem !important;
}

.account-group-label-balance>span {
    font-size: 1.5rem !important;
    font-weight: bold !important;
}

.account-balance {
    font-size: 1rem !important;
    font-weight: bold !important;
}

.empty-span-accounts {
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 5px;
    display: inline-block;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-bottom-10 {
    margin-bottom: 10px !important;
}

.border-bottom-1 {
    border-bottom: 1px solid #e0e0e0 !important;
}

.border-bottom-0 {
    border-bottom: 0 !important;
}

.green-button {
    background-color: #8bc34a !important;
}

.user-options {
    float: right;
    padding-right: 15px;
    background: none;
}

div.user-options button {
    background: none;
    text-transform: none;
}

div.user-options button i {
    line-height: 35px;
    height: 35px;
}

ul.dropdown-content li {
    min-height: 15px;
    padding: 6px;
}

ul.dropdown-content li.divider {
    padding: 0;
}

ul.dropdown-content li i.material-icons {
    float: left;
    line-height: 23px;
    margin-right: 5px;
    height: 23px;
}

.user-account-icon {
    margin: 0;
    vertical-align: middle;
    margin-top: 18px;
    margin-right: 5px;
}

.input-field select {
    border: none;
    border-bottom: 1px solid gray;
}

.collection-item span.leftBadge {
    float: left;
    margin-right: 14px;
    margin-left: 0;
    min-width: 1.5rem;
    border-radius: 11px;
}

nav .brand-logo {
    display: flex;
    align-items: center;
    margin-left: 10px;
}

nav .brand-logo .logo_text {
    color: white;
    font-size: 28px;
    margin-left: 5px;
    vertical-align: middle;
}

.search {
    box-sizing: border-box;
    background-color: #7CA4EF;
    border: 0.5px solid gray;
    height: 30px;
    width: 50%;
    color: white;
}

#search {
    max-width: 0;
    width: 800px;
    color: black;
    background-color: white;
    margin-bottom: 0;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    box-sizing: border-box;
    opacity: 0;
    height: 40px;
    border-radius: 6px;
}

#search.expanded {
    max-width: 500px;
    opacity: 1;
}

.search:hover {
    background-color: #7CA4EF;
}


@media only screen and (max-width : 992px) {
    .Sidenav {
        padding-left: 0;
        height: calc(100vh - 56px);
    }

    nav .brand-logo {
        margin-left: 40px;
        left: 110px;
    }
}

@media (max-width: 640px) {
    nav .brand-logo {
        margin-left: 30px;
        left: 75px;
    }

    nav .brand-logo img {
        height: 33px !important;
    }

    nav .brand-logo .logo_text {
        font-size: 20px;
    }

    nav ul a {
        padding: 0 15px 0 5px;
    }
}

.card.darken-1 {
    word-break: break-all;
}

.orLink {
    margin-top: 20px;
    font-size: large;
}

.social-icons li {
    display: inline-block;
    padding: 0 15px;
}

@media only screen and (min-width : 600px) {
    .orLink {
        text-align: right;
        font-size: medium;
    }
}

.dropdown-content li>span {
    color: #000000;
}

.day-round {
    background: orange;
    line-height: 50px;
    text-align: center;
    border-radius: 56px;
    font-size: 20px;
    width: 50px;
    margin-right: 15px;
}

.tab-title {
    line-height: normal;
    padding-top: 7px;
    text-transform: uppercase;
}

.red-text {
    color: red;
}

.floating-patch {
    float: left;
    width: 100%;
}

.bill-grey-month {
    background-color: #d2d5d8;
    color: #000000;
    padding-left: 5px;
}

.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    /* background: black url(spinner.gif) center center no-repeat; */
    opacity: .5;
}

.loader {
    position: fixed;
    left: 50%;
    top: 35%;
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 2s linear infinite;
    /* Safari */
    animation: spin 2s linear infinite;
}

.loader-box {
    position: fixed;
    left: 50%;
    top: 35%;
}

/* Safari */
@-webkit-keyframes spin {
    0% {
        -webkit-transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.bill-grey-text {
    color: #d2d5d8;
    padding-left: 5px;
}

.grey-disclaimer {
    color: #797979;
}

.padding-10 {
    padding: 10px;
}

.dark-grey-text {
    color: #797979 !important;
}

.border-top-0 {
    border-top: 0 !important;
    margin-top: 0 !important;
}

.date-label {
    display: block;
    line-height: 32px;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 300;
}

table.no-padding td {
    padding: 0;
}

.categories {
    overflow: hidden;
    text-overflow: ellipsis;
}

.row.mb-0 {
    margin-bottom: 0;
}

.fix-height-tab {
    height: 400px;
    overflow-y: auto;
}

.tabs .tab a {
    color: rgba(134, 129, 129, 0.7);
}

.tabs .tab a:hover,
.tabs .tab a.active {
    background-color: transparent;
    color: #000000;
}

.bottom-check {
    margin: 0;
    height: 15px;
    position: relative;
    bottom: 10px;
    left: 21px;
}

.overlap-tab-filter {
    padding: 0 10px;
    position: absolute;
    right: 0;
    z-index: 99;
    text-align: center;
}

.overlap-tab-filter .input-field {
    margin-top: 0;
}

.year-selector {
    display: inline-flex;
}

.year-selector img {
    padding-left: 4px;
}

.year-selector i.material-icons {
    cursor: pointer;
}

.year-selector div.value {
    margin-top: 1px;
    padding: 0 10px;
}

.year-selector div.value .title {
    font-size: 1rem;
    margin: 0;
}

.font-size-1point2-rem {
    font-size: 1.2rem !important;
}

.no-wrap-day-round {
    word-break: keep-all;
    overflow-x: hidden;
}

.font-size-1point1-rem {
    font-size: 1.1rem !important;
}

.font-size-1point5-rem {
    font-size: 1.5rem !important;
}

.year-selector i.material-icons:hover,
div.add-icon:hover {
    background: #e6e6e6;
    border-radius: 50%;
}

ul.tabs li.indicator {
    height: 4px;
    background-color: #dd000b;
}

ul.tabs li.tab {
    font-weight: 600;
}

div.add-icon {
    display: inline-block;
    cursor: pointer;
    line-height: 1;
    padding: 10px 0;
}

div.add-budget-icon {
    display: inline-table;
    cursor: pointer;
    line-height: 1;
    bottom: 58px;
    right: 25px;
    position: absolute;
}

table.table-category-selector td {
    padding: 0;
}

div.billing-tabs {
    position: relative;
}

div.billing-tabs ul {
    height: 95%;
    white-space: nowrap;
    overflow-x: hidden;
    overflow-y: auto;
}



.fix-height-tab .collection .collection-item table tbody tr td {
    position: relative;
}

.fix-height-tab .collection .collection-item table tbody tr td .bottom-check {
    position: absolute;
}

.monthly-budget {
    width: 100%;
    float: left;
}

.monthly-budget li {
    width: 100%;
    float: left;
}

.monthly-budget li ul {
    width: 100%;
    float: left;
}

ul.table-dicsss li p {
    margin: 0;
    padding: 0;
}

ul.table-dicsss li p:last-child {
    color: rgb(165, 159, 169);
    padding-top: 14px;
}

.date-label {
    color: #000;
    padding-bottom: 6px;
    white-space: nowrap;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    max-width: 310px;
    min-width: 310px;
    overflow: hidden;
    text-transform: capitalize;
}

.table-dicsss {
    position: relative;
}

.table-dicsss:after {
    width: 86.7%;
    height: 2px;
    background: #25ea5a99;
    position: absolute;
    top: 34px;
    right: 0;
    content: "";
}

.col.m6 .input-field .prefix~label {
    margin-left: 2rem;
}

.static-text .input-field .prefix {
    left: 0;
    font-size: 22px;
}

.static-text .input-field h5 {
    padding-left: 17px;
    font-size: 20px;
}

.modal .modal-content {
    position: relative
}

.modal {
    width: 40%;
}

.modal-footer-sec {
    width: 100%;
    float: left;
    border-top: solid 1px #ccc;
    position: absolute;
    bottom: -45px;
    left: 0;
    padding-left: 30px;
}

.static-text .left-value h5 {
    color: #00ab00;
}

.static-text .left-value.danger h5 {
    color: #f00;
}

.static-text h6 {
    color: #bebebe;
}

li.collection-item table .border-bottom td {
    border-bottom: solid 5px #bae29b;
}

li.collection-item table .border-bottom.danger td {
    border-bottom: solid 5px #f00;
}

li.collection-item table .border-bottom td:first-child {
    border-bottom: 0;
}

.static-text h3 {
    padding: 0;
    margin: 0;
    font-size: 2.7rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.static-text h5 {
    margin: 0;
}

.input-date input[type="month"]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-field input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.attach-Photo label {
    margin: 0 !important;
    padding: 0;
    display: flex;
    position: absolute;
    left: 0 !important;
    top: -13px;
}

label {
    word-break: normal !important;
}

.attach-Photo label i {
    margin-right: 5px;
}

.attach-Photo input {
    margin-left: 0 !important;
    opacity: 0;

}

.attach-Photo button {
    margin-top: 10px;
    width: 136px;
    line-height: 35px;
    border-radius: 5px;
}

.images-up img {
    width: 40%;
}

.input-expense-date input[type="date"]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.input-expense-date input[type="time"]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add-edit-expense .row .input-field {
    width: 100%;
}

.input-field.col.m6.s6.attach-Photo {
    width: 50%;
}

.input-field.col.m6.s6.images-up {
    width: 50%;
}

.input-field.attach-Photo label:not(.label-icon).active {
    transform: translateY(16px) scale(0.8);
}

.input-expense-date input[type="date"] {
    position: relative;
    color: #000;
}

.input-expense-date input[type="date"]::-webkit-calendar-picker-indicator {
    display: block;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.expense-image {
    position: relative;
    min-height: 51px;
}

.expense-image .loader-box {
    position: absolute;
    left: 0;
    top: 0;
}

.sweet-alert {
    color: #fff !important;
}

.sweet-alert-container {
    position: fixed !important;
    left: 23px !important;
    bottom: 60px !important;
    max-width: 100% !important;
}


#modal1 table.table-category-selector tr td [type="radio"]:checked+span,
#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span {
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-block;
    float: left;
    width: 18px;
    height: 18px;
    margin-top: 2px;
}

#modal1 table.table-category-selector tr td [type="radio"]:checked+span:before,
#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 18px;
    height: 18px;
    border: 1px solid #ccc;
    border-radius: 100%;
    background: #fff;
}

#modal1 table.table-category-selector tr td [type="radio"]:checked+span:after,
#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span:after {
    content: '';
    width: 10px;
    height: 10px;
    background: #ccc;
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}

#modal1 table.table-category-selector tr td [type="radio"]:not(:checked)+span:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}

#modal1 table.table-category-selector tr td [type="radio"]:checked+span:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

.input-income-date {
    position: relative;
    width: 30%;
    display: inline-block;
}

.input-income-date input[type="date"]::-webkit-calendar-picker-indicator,
.input-income-date input[type="date"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.view-image {
    text-align: center;
    overflow: auto;
    height: 320px;
}

.bill-icon {
    color: #2196f3;
    font-size: 40px !important;
    /* //transform: scale(1.5); */
}

.payment-icon {
    color: #2196f3;
}

.wave-btn:hover {
    background-color: transparent;
}

div.add-icon:hover {
    background: #2196f30f;
    border-radius: 11%;
    padding: 10px 0;


}

.payment-btn:focus {
    background: transparent;
}

.input-date input[type="month"]::-webkit-calendar-picker-indicator {
    display: block;
    -webkit-appearance: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}

.active-record {
    background: #bbdefb42 !important;
}

.image-relative {
    position: relative;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}


.table-align td,
.table-align th {
    vertical-align: top;
}

.table-align .leftMargin10 {
    margin: 4px 10px 0 0;
}

.table-align tr td.categories .text-break {
    float: left;
    width: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

ul.collection.fix-height-table li.collection-item:last-child {
    margin-bottom: 0 !important;
}

ul.collection.fix-height-collection li.collection-item:last-child {
    margin-bottom: 0 !important;
}


.input-field.col.m6.s6.attach-Photo label i {
    color: #000;
}

.input-field.col.m6.s6.attach-Photo label {
    color: #000;
}

.saving-report-label {
    margin-bottom: 0;
}

.saving-report-label .col>div {
    border-right: 1px solid;
    margin: 0 20%;
    float: left;
}

.saving-report-label .col:last-child div {
    border: none;
}

.saving-report-label b {
    width: 100%;
    float: left;
}

.saving-report-label p {
    position: relative;
    float: left;
    width: auto;
    padding-right: 16px;
}

.saving-report-label p:after {
    position: absolute;
    right: 0;
    top: 6px;
    width: 12px;
    height: 12px;
    border-radius: 100%;
    content: "";
}

.saving-report-label .saving-circle p:after {
    background: #3785bc;
}

.saving-report-label .expenses-circle p:after {
    background: #c04d36;
}

.saving-report-label .income-circle p:after {
    background: #87af6a;
}

.saving-circle span,
.expenses-circle span,
.income-circle span {
    color: #000;
}

.trend-legend p {
    text-align: left;
    padding-left: 20px;
    font-size: large;
}

.trend-legend p i {
    width: 14px;
    height: 14px;
    background: #c04d36;
    margin: 0 7px;
}

.radio-input-button label {
    position: relative;
    margin-right: 13px;
    float: left;
}

.radio-input-button label input[type="radio"]:not(:checked),
.radio-input-button label input[type="radio"]:checked {
    position: relative;
    opacity: 1;
    pointer-events: all;
}

.radio-input-button label input[type="radio"]:checked {
    position: relative;
    opacity: 1;

}

.radio-input-button label input {
    float: left;
    top: 5px;
}

.radio-input-button label span {
    color: #000;
    font-size: larger;
}

.overdue span {
    color: #c04d36 !important;
}

.saving span {
    color: #87af6a !important;
}

.saving-legend {
    text-align: center;
}

.saving-legend span .box {
    text-align: left;
    font-size: large;
}

.saving-legend span .box {
    width: 14px;
    height: 14px;
    margin: -3px 7px;
    display: inline-block;
    text-align: center;
    border-radius: 50%;
}

.input-block {
    display: flex;
    align-items: center;
}

.input-block .input-box {
    width: 100%;
}

.input-block .input-box select {
    border: 0;
    border-bottom: 1px solid #9e9e9e;
    width: 100%;
}

.input-block .input-box label {
    margin-left: 14px;
}

.content-area {
    overflow-y: auto;
    width: 104%;
    padding-right: 20px;
    overflow-x: hidden;
}

.Details-section,
.expenses-details-section,
.income-section,
.budget-section {
    padding-left: 10px;
}

.Details-section .innerPage table,
.income-section table {
    margin-top: 20px;
    margin-left: 20px;
    width: 90%;
}

span {
    word-break: normal;
}

.Details-section ul.tabs li.indicator {
    min-width: 100px;
}

ul.tabs li.indicator {
    min-width: 100px;
}

table.budget-no-padding.budget-monthly-category tr td img {
    height: 50px
}


.version-footer {
    text-align: center;
    position: fixed;
    width: 100%;
    bottom: 130px;
}

.version-footer p {
    margin: 0;
}

.total-price {
    position: relative;
    padding-top: 16px !important;
    text-align: right;
    padding-right: 14px !important;
}

.month-selector {
    background: rgb(246, 247, 248);
    font-weight: bold;
    padding-bottom: 7px;
}

.img-preview .swal2-popup {
    padding: 4px;
}

.img-preview .swal2-content {
    display: block;
    overflow: scroll;
    height: 420px;
}

.img-preview .swal2-close {
    background: none;
    right: 2%;
}

.img-preview .swal2-popup.swal2-modal.swal2-show {
    width: 70%;
}

#siteseal {
    position: fixed;
    width: 170px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: row-reverse;
    flex-direction: row-reverse;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
    direction: rtl;
    bottom: 13px;
    left: 9%;
}

#siteseal img {
    height: 32px !important;
    width: auto !important;
}

.recaptcha div {
    margin: 0 auto;
}

.margin-bottom-0 {
    margin-bottom: 0;
}

.margin-0 {
    margin: 0;
}

.item-center {
    text-align: center
}

.word-break {
    word-break: normal;
    text-align: center;
}

.nowrap {
    white-space: normal;
}

.content-ltr {
    direction: rtl;
}

.profile-image {
    width: 214px;
    height: 214px;
    border: solid 8px #e8f3fd;
    display: flex;
    margin: auto;
    background: #e8e3e3;
    object-fit: cover;
    box-sizing: content-box;
}

.choose-file-input {
    margin: 10px 0;
    width: 100%;
    position: relative;
    display: flex;
}

.choose-file-input input {
    width: 100%;
    background: #000;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    cursor: pointer;
}

.choose-file-input input:focus {
    outline: inherit;
}

.profile-picture {
    float: left;
    position: relative;
    margin: auto;
    display: flex;
    width: auto;
    align-items: center;
    align-content: baseline;
}

.profile-picture i {
    position: absolute;
    top: 40%;
    left: 40%;
    width: 25px;
    height: 25px;
    background: #1976d2;
    color: #fff;
    font-size: 14px;
    text-align: center;
    line-height: 25px;
    border-radius: 62%;
    transform: translate(40%, 40%);
}

.profile-picture i.material-icons.clear {
    right: 0;
    top: -20px;
    left: inherit;
    cursor: pointer;
}

.choose-file-input button {
    width: auto;
    margin: 10px auto;
    display: flex;
}

.card-content.change-password .row .col {
    padding: 0 .75rem;
}

.change-password .input-block {
    width: 70%
}

.change-password .input-field.col {
    width: 70%;
}

.row.flex-container {
    display: flex;
    align-items: center;

}

.flex-box {
    display: flex;
    align-items: center;
}



.recharts-wrapper {
    width: 100% !important;
    height: initial !important;
}

.recharts-wrapper svg:not(:root) {
    overflow: visible;
    width: 100%;
    height: 100%;
}

.row.flex-container .col {
    padding: 0 .75rem;
}

.black-colored-checkbox>.col>[type="checkbox"]+label {
    color: black;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.reports-section-page .no-padding .card-content {
    padding: 0;
}

.reports-section-page .saving-report-label .col>div {
    border-right: 1px solid;
    margin: 0;
    float: left;
    width: 100%;
    padding: 18px;
    box-sizing: border-box;
}

.reports-section-page .saving-report-label .col>.saving-circle.saving {
    border-right: 0;
}

.Trends-svg {
    position: relative;
}

.Trends-svg .recharts-wrapper {
    width: 800px;
    height: 500px;
}

.flex-container {
    display: -webkit-flex;
    display: flex;
    margin-top: 15px;
}

.flex-container .column {
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
}

.input-min-height .input-field {
    width: 100%;
}

.input-min-height .input-field input {
    min-height: 50px;
}

.button-upload {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 13px;
}

.row.flex-container {
    margin-bottom: 0;
}

.sign-in-section .row .col {
    padding: 0 .75rem;
}

.header-avatar-div {
    float: left;
}

.header-avatar-div>img {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin-top: 8px;
    margin-right: 2px;
}

.header-name-arrow {
    display: flex;
    align-items: center;
}

.pointer-class {
    cursor: pointer;
}

.column.input-min-height .input-block .input-box {
    width: 100%;
    min-height: 86px;
}

#siteseal {
    display: none !important;
}

.certificates-footer {
    width: 100%;
    float: left;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

.certificates-footer img {
    width: 110px;
    float: left;
}

.certificates-footer img:first-child {
    width: 44px;
}

.sign-in-section {
    display: flex;
    justify-content: space-around;
    height: calc(100vh - 100px);
}

.sign-in-section .card-content {
    margin-top: 20px;
}

.sign-in-section .input-field input {
    padding-left: 12px;
    padding-right: 12px;
    border: solid 1px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-color: rgb(184, 196, 194);
    border-radius: 3px;
}

.sign-in-section .input-field input:focus {
    box-shadow: 0 0 0 2px rgba(38, 166, 154, 0.73) !important;
    border: solid 1px rgba(38, 166, 154, 0.73);

}

.sign-in-section .input-field label {
    padding-left: 12px;
    color: gray;
}

.sign-in-section .card.darken-1 {
    width: 32%;
    margin: auto;
}

.sign-in-section .input-field label.active {
    top: -7px;
    transition: transform 400ms cubic-bezier(0.25, 0.8, 0.25, 1), color 400ms cubic-bezier(0.25, 0.8, 0.25, 1), width 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

.border-remove {
    border: 0;
}

.padding-remove .card-content {
    padding: 0;
}


.padding-remove .card-content .card-title {
    margin: 0;
    padding: 24px 0 0 24px;
}

.back-button {
    color: #000;
    margin-left: 10px;
    margin-top: 5px;
    width: auto;
    float: left;
    padding: 1px 7px;
    border-radius: 7px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    display: none;
    cursor: pointer;
    font-weight: normal;
}

.back-button i {
    padding-right: 5px;
}

.card.darken-1.template-section form div p {
    font-size: 15px;
    float: left;
    width: 100%;
    margin-top: 10px;
}

.template-section .radio-input-button label span {
    color: #000;
    font-size: larger;
    margin-left: 5px;
}

.email-id-title {
    padding-left: 7px;
}

.template-page ul#mobile-demo {
    display: none;
}

.template-page a.left.hide-on-large-only {
    display: none;
}

.header-user-icons {
    float: left;
    display: flex;
    align-items: center;
    padding: 0 16px;
    width: 100%;
}

.header-user-icons img {
    margin-top: 0;
}

.profile-save-button {
    float: right;
    margin-bottom: 17px;
    width: 100%;
    text-align: right;
}

.column.input-min-height.Password-section-change .col {
    padding: 0;
}

.input-field.col.m10.s12.last-input .col.input-field.s12 {
    padding: 0;
}

.input-field.col.m10.s12.last-input label {
    padding: 0;
}

.row.margin-0.Already-div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    flex-direction: column;
}

.text-align-center {
    text-align: center;
}

.row.margin-0.Already-div .text-align-center button {
    width: 60%;
}

.row.margin-0.Already-div .text-align-center a {
    padding-left: 5px;
}

.by-clicking {
    text-align: center;
}

.top-Spends-left .col {
    padding: 0 .75rem;
}


.block-2 li {
    width: 49%;
    float: left;
    margin: 0.5% !important;
}


.tabs {
    height: 60px;
}

.tabs li {
    height: 60px;
    line-height: 60px;
}

.user-options ul li a {
    color: rgba(0, 0, 0, 0.87);
}

.user-options ul li i {
    color: rgba(0, 0, 0, 0.54);
}

.react-tel-input .flag-dropdown {
    background-color: transparent !important;
    border: 0 !important;
    border-radius: initial !important;
}

.react-tel-input .country-list {
    z-index: 1;
    list-style: none;
    position: absolute;
    padding: 0;
    margin: 0 0 10px -1px;
    -webkit-box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    box-shadow: 1px 2px 10px rgba(0, 0, 0, 0.35);
    background-color: white;
    width: 300px;
    max-height: 270px;
    overflow-y: scroll;
    border-radius: 0 0 3px 3px;
    min-height: 186px;
}

.card-content.change-password .btn {
    margin-top: 25px;
}


.user-options .btn:hover {
    background-color: #bbdefb;
    color: #000;
}

.phone-input {
    box-sizing: border-box !important;
}

.bills-details-table tr td:nth-child(1) {
    width: 90px;
}

div.billing-tabs ul li,
.tab-mobile-fixed ul li {
    border-bottom: solid 4px #ffffff;
}

div.billing-tabs ul li.active-tab,
.tab-mobile-fixed ul li.active-tab {
    border-bottom: solid 4px #dd000b;
}

.no-padding-mobile ul li .col {
    padding: 0 !important;
}

.recharts-responsive-container.valign-wrapper {
    height: initial !important;
}

.recharts-responsive-container.valign-wrapper svg {
    width: 90% !important;
}

.Top-Spends-recharts .recharts-responsive-container.valign-wrapper svg {
    width: 90% !important;
}

.Top-Spends-recharts .recharts-responsive-container.valign-wrapper {
    text-align: center;
}

.loading-all-page {
    width: 100%;
    height: calc(100vh - 119px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.loading-all-page h1 {
    font-size: 22px;
}

.tab-mobile-fixed {
    width: 100%;
}

.font-size-1rem {
    font-size: 1rem !important;
}

.detail-tab li {
    border-bottom: solid 4px #dd000b;
    height: inherit !important;
}

.detail-tab li.indicator {
    display: none;
}

.Trends-svg .recharts-wrapper svg {
    width: auto;
    height: auto;
}

.min-height346 {
    min-height: 346px;
}

.min-height346 .row.flex-container {
    min-height: 275px;
}

.user-options ul.dropdown-content.active {
    width: auto !important;
    min-width: 250px;
}

.submit-loader-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.template-row-margining {
    margin: 5px 0 35px 0 !important;
}

.question-body {
    margin-bottom: 20px !important;
    font-size: 16px !important;
}

.template-title {
    display: flex;
    justify-content: center;
    font-size: 30px !important;
}

.template-subtitle {
    display: flex;
    justify-content: center;
    font-size: 18px;
    font-weight: normal !important;
    margin-bottom: 40px;
}

.reduced-space-label-symbol+label {
    padding-left: 25px !important;
    margin-right: 25px !important;
    color: #36454f !important;
}

.mandatory-asterisk p:after {
    color: #e32;
    content: ' *';
    font-weight: 900;
    display: inline;
}

.template-question {
    font-size: 18px !important;
}

.template-row-margining .radio-input-button [type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:after {
    background-color: #1976d2;
}

.template-row-margining .radio-input-button [type="radio"]:checked+label:after,
[type="radio"].with-gap:checked+label:before,
[type="radio"].with-gap:checked+label:after {
    border: 2px solid #1976d2;
}

.template-row-margining .radio-input-button [type="checkbox"]:checked+label:before {
    border-right: 2px solid #1976d2;
    border-bottom: 2px solid #1976d2;
}

.template-select {
    display: block;
    width: 33%
}

@media only screen and (max-width : 992px) {
    .template-select {
        width: auto
    }
}

.activate-page .card-title {
    word-break: normal !important;
}

.not-found span {
    word-break: normal !important;
}

.reset-password-section h3 {
    font-size: 19px;
    padding: 0;
    margin: 0;
}

.reset-password-section .row .col {
    padding: 0 .75rem;
}

.reset-password-section .input-field.col.l11.m11.s12 {
    margin-bottom: 15px;
}

.reset-password-section input {
    padding-left: 5px;
}

.green-color span {
    word-break: normal !important;
}

.long-symbole {
    font-size: 1.4rem !important;
    padding-top: 15px;
}

.disclaimer-content {
    padding: 5px;
    display: flex;
    justify-content: center;
    word-break: normal;
}

.disclaimer-box {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 9;
}

.hide-disclaimer-div {
    display: none !important;
}

.close-disclaimer {
    margin-left: 10px;
    cursor: pointer;
}

.disclaimer-content p {
    display: flex;
    align-items: center;
}

.disclaimer-content a {
    margin-right: 5px;
    margin-left: 5px;
}

.wrap-it {
    display: contents;
}

.disclaimer-box .card .card-content {
    padding: 10px !important;
}

.disclaimer-box .card {
    margin-bottom: 0 !important;
    background-color: #fff3c2;
}

.input-field .prefix {
    width: 4rem;
}

.input-field .prefix~input,
.input-field .prefix~textarea,
.input-field .prefix~label,
.input-field .prefix~.validate~label,
.input-field .prefix~.autocomplete-content {
    margin-left: 4rem;
    width: calc(100% - 4rem);
}

.message-icon {
    font-size: 24px !important;
}

.display-flex {
    display: flex;
}

.align-horizontal {
    display: flex;
    align-items: center;
}

.margin-bottom-40 {
    margin-bottom: 40px;
}

.date-flex {
    display: flex;
    align-items: center;
    margin-bottom: 30px !important;
    flex-direction: column;
    margin-top: -15px;
}

.date-flex .input-field.col.m6.s12.input-expense-date {
    margin-top: 0;
}

.date-flex .input-field.col.m6.s12.input-expense-date label {
    font-size: 0.8rem;
}

.date-flex label {
    position: relative;
}

.date-flex span#date-text {
    margin-left: 0 !important;
    float: right !important;
    width: 100% !important;
    margin-top: 8px;
}

h5.zero-margin {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #989898;
    font-size: 11px;
    text-align: center;
    line-height: 21px;
    color: #fff;
    font-weight: 500;
}

h5.zero-margin-detail {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: #989898;
    font-size: 20px;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-weight: 500;

}

.margin1-1 {
    margin-left: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px !important;
}

.margin-bottom-50 {
    margin-bottom: 50px !important;
}

.margin-top-10 {
    margin-top: 10px !important;
}

.margin-top-20 {
    margin-top: 20px
}

.margin-top-30 {
    margin-top: 30px
}

.margin-top-35 {
    margin-top: 35px
}

.auto-margin {
    margin: auto;
}

.point-icon {
    width: 15px;
    height: 15px;
    margin-right: 10px;
    margin-top: 4px;
}

.text-transform-none {
    text-transform: none;
}

table.budget-no-padding.budget-monthly-category tr td:nth-child(2) {
    max-width: 150px;
    display: inline-block;
}

table.budget-no-padding.budget-monthly-category tr td:nth-child(3) {
    max-width: inherit;
}

.income-table-monthly tr td:nth-child(2) {
    max-width: 270px;
}

.UpcomingTable tr td:nth-child(2) {
    max-width: 270px;
}


.bills-monthly-category tr td:nth-child(3),
.income-table-monthly tr td:nth-child(3) {
    text-align: right;
    float: right;
}


.bills-monthly-category tr td .date-label {
    max-width: inherit;
    min-width: inherit;
}

table.budget-no-padding.budget-monthly tr td span {
    word-break: break-all !important;
}

.balance-label {
    font-weight: normal;
    margin: 0;
    margin-top: 4px;
}

.balance-label p {
    margin: 0;
}

.balance-label-bill {
    font-weight: normal;
    margin: 0;
    margin-top: 4px;
}

.blue-text {
    color: #2196f3;
}

.padding-l-5 {
    padding-left: 5px;
}

.height-30 {
    height: 30px;
    padding: 3px;
}

.min-width-100 {
    min-width: 100px;
}

.notice-ui {
    background-color: rgb(240, 248, 255);
    padding-top: 10px;
    display: inline-flex;
    width: 100%;
    padding-left: 20px;
    padding-right: 10px;
}

.notice-ui .text-nowrap {
    white-space: nowrap;
}

.notice-ui img {
    width: 70%;
}

.relative-pos {
    position: relative;
}

.timezone-notice {
    background-color: rgb(240, 248, 255);
    padding-top: 10px !important;
    margin-top: 2px;
    padding-bottom: 5px !important;
    padding-right: 10px !important;
}

.height-20 {
    height: 20px !important;
}

.height-54 {
    height: 54px;
}

.yearSelector img {
    padding-left: 0 !important;
    margin-right: 0;
}

img.zero-margin.height-20 {
    width: 20px;
    height: 20px;
    border-radius: 100%;
}

.accountDetailCategories .text-break.grey-text {
    width: 250px !important;
}

.accountDetailCategories .text-break {
    width: 250px !important;
}

.break-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.backdrop-div {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(207, 209, 207, 0.35);
    z-index: 9999;
    width: 100%;
    height: 100%;
}

.import-trx-div {
    border: 2px solid #1976D2;
    padding: 10px;
    border-radius: 5px;
    background: #F0F8FF;
    color: #000000;
}

.import-steps {
    margin-top: 5px;
    font-size: 14px;
    color: #000000;
}

.font-size-16-bold {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

.color-black {
    color: #000000;
}

.margin-top-20 {
    margin-top: 20px
}

.padding-8 {
    padding: 8px;
}

.google-btn {
    width: 80%;
    /* Button width at 80% */
    display: flex;
    /* Flexbox for centering content */
    align-items: center;
    /* Vertical centering */
    justify-content: center;
    /* Horizontal centering */
    padding: 10px;
    /* Padding for better spacing */
    border: 1px solid rgba(0, 0, 0, 0.2);
    /* Thin border with slight transparency */
    border-radius: 5px;
    /* Rounded corners */
    /* box-shadow: 0px 2px 4px rgba(233, 244, 254, 0.8); */
    /* Subtle shadow */
    background-color: white;
    /* Background color */
    cursor: pointer;
    /* Pointer cursor for buttons */
    transition: box-shadow 0.3s ease, transform 0.2s ease;
    /* Smooth effects on hover */
    margin: 0 auto;
    /* Center the button horizontally */
}

.google-btn:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    /* Enhanced shadow on hover */
    transform: scale(1.02);
    /* Slight scaling effect */
}

.google-btn img {
    margin-left: 8px;
    /* Spacing between text and logo */
    vertical-align: middle;
    /* Align logo with text */
}
body {
    margin: 0;
}
.family-budget-section {
    position: relative;
    padding-top: 130px !important;
    margin-top: 50px;
}
.family-budget-section::before, .family-budget-section::after {
    content: '';
    background-size: cover;
    width: 100%;
    left: 0;
    right: 0;
    background-repeat: no-repeat;
    position: absolute;
    height: 590px;
}


.family-container {
    position: relative;
    z-index: 2;
}

.family-budget-heading-block h2 {
    font-size: 64px;
    color: #2B2B2B;
    line-height: 119%;
    letter-spacing: -0.64px;
}
.family-budget-heading-block h2 span {
    color: #007ED3;
}

.family-budget-heading-block > span {
    color: #4B5563;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 5px;
    display: block;
}

.family-budget-heading-block > p {
    color: #007ED3;
    font-size: 20px;
    font-weight: 600;
    line-height: 120.908%;
    margin-bottom: 5px;
}


.family-budget-right-block {
    width: 100%;
    height: 600px;
    border-radius: 298px 298px 37px 37px;
    background: #E3F2FF;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    position: relative;
}
.family-budget-right-block img {
    max-width: 100%;
    height: 450px;
}
.family-budget-right-block img.top-img {
    position: absolute;
    top: -50px;
    width: 450px;
    left: -140px;
    height: 280px;
}
.family-budget-right-block img.bottom-img {
    height: 360px;
    position: absolute;
    bottom: 10px;
    width: 560px;
    left: -380px;
    max-width: fit-content;
}
.money-manage-section {
    padding: 100px 0;
}
.money-manage-section .money-manage-left-block {
    position: relative;
    display: flex;
    flex-direction: column;
    z-index: 1;
    width: 100%;
}
.money-manage-section .money-manage-left-block img {
    border-radius: 17px;
    position: relative;
    z-index: 2;
    margin: auto;
    max-width: 100%;
}

.money-manage-section .money-manage-right-block p {
    margin-bottom: 0;
    margin-top: 20px;
    font-size: 20px;
    font-weight: 500;
    line-height: 160%;
}
.money-manage-section .money-manage-right-block h2 {
    font-weight: 600;
    font-size: 48px;
    line-height: 130%;
    letter-spacing: -1px;
    width: 546px;
    color: #2B2B2B;

}
.money-manage-section .money-manage-right-block h2 span {
    color: #007ED3;
}

.text-light-cyan {
    color: #11C7AA;
}
.text-dark-black-shade {
    color: #2B2B2B;
}
.text-light-gray-shade {
    color: #4B5563;
}
.text-new-blue {
    color: #007ED3 !important;
}
/* section {
    padding: 60px 0 !important;
} */

.accordion-budgeting .panel-title span {
    color: #2b2b2b;
}
.accordion-budgeting.accordion-style-03 .panel-heading {
    background: none;
}
.accordion-budgeting.accordion-style-03 .panel {
    background: #f8f8f8;
}
.accordion-budgeting.accordion-style-03 .panel .panel-body {
    padding: 0 20px 20px;
    color: #6B7280;
}
.accordion-style-03 .panel .panel-heading i {
    top: 34px;
}
.bg-off-white {
    background-color: #F9FAFB;
}

.tb-content-box-icon {
    background-color: #EFF8FF;
    width: 55px;
    height: 55px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: absolute;
    top: -28px;
    left: 0;
    right: 0;
}
.tb-content-box-icon img {
    max-width: 70%;
    margin-left: 0;
    margin-top: 0;
}
.tb-content-box {
    border: 1px solid transparent;
}
.tb-content-box:hover {
    transform: none;
    cursor: pointer;
    border: 1px solid #007ED3;
    background-color: #fff;
}
.tb-content-box h3 {
    font-size: 20px;
    font-weight: 600;
    color: #2b2b2b;
}
.tb-content-box p {
    font-size: 16px;
    margin-bottom: 0;
    color: #4B5563;
}
.tb-content-box:hover h3 {
    color: #2b2b2b;
}

.your-class .slick-arrow{
    width: 45px;
    height: 45px;
    position: absolute;
    top: 50%;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FEFDFD;
    box-shadow: 0px 4px 30px 0px rgba(1, 1, 20, 0.10);
    margin-top: -70px;
    z-index: 9;
    border: 0;
    font-size: 0;
}
.your-class .slick-prev::before {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    /* background-image: url('../images/family_budgeting_new/slick-arrow-left.svg'); */
    background-size: cover;
}
.your-class .slick-prev {
    left: -30px;
}
.your-class .slick-next {
    right: 15px;
}
.your-class .slick-next::before {
    content: '';
    width: 25px;
    height: 25px;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    /* background-image: url('../images/family_budgeting_new/slick-arrow-right.svg'); */
    background-size: cover;
}

.your-class .slick-dots button {
    display: none;
}
.your-class .slick-dots li::marker {
    display: none;
}
.your-class .slick-dots {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 10px;
    justify-content: center;
    list-style: none;
    margin-top: 50px;
}
.your-class .slick-dots li {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgba(0, 138, 255, 0.30);

}

.your-class .slick-dots li.slick-active {
    width: 21px;
    height: 21px;
    background-color: #008AFF;
}

.your-class .branding-bg {
    border-radius: 27px;
    border: 1px solid #007ED3;
    background: #FFF;
    padding: 25px;
    align-items: flex-start;
    margin-right: 25px;
    width: 31%;

}
.your-class .branding-bg .branding-bg-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #EFF8FF;
}
.your-class .branding-bg .branding-bg-icon img {
    width: 30px;
}
.your-class .branding-bg h3 {
    color: #2B2B2B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    padding: 20px 0;
    margin: 0;
}
.your-class .branding-bg p {
    color: #4B5563;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
    text-align: left;
}

.your-class  .slick-track
{
    display: flex !important;
}

.your-class  .slick-slide
{
    height: inherit;
    flex: 1;
}

.your-class .slick-list {
    padding-right: 20px;
}

.color-boxes {
    border-radius: 24px;
    background: #EEF8FF;
    width: 100%;
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
}
.color-boxes .user-photo-block {
    margin-bottom: 15px;
}
.color-boxes > p {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #061C3D;
}
.color-boxes .user-photo-block img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
}
.color-boxes .user-photo-block span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px; 
    color: #061C3D;
}
.quick-glance-app-section {
    background-color: #007ED3;
}
.mobile-app-slider {
    position: relative;
    z-index: 3;
}
.mobile-app-slider .mobile-app-container {
    height: 600px;
    border-radius: 16px;
    border: 5px solid rgba(255, 255, 255, 0.08);
    margin-right: 15px;
    width: 289px !important;
}
.mobile-app-slider .mobile-app-container img {
    height: 100%;
    border-radius: 16px;
    width: 100%;
}
.mobile-app-slider .mobile-app-container:nth-child(even) {
    margin-top: 100px;
}
.your-class.mobile-app-slider .slick-prev {
    left: 0;
}
.your-class.mobile-app-slider .slick-next {
    right: 0;
}
.your-class.mobile-app-slider .slick-arrow {
    top: inherit;
    bottom: -12px;
}
.your-class.mobile-app-slider .slick-dots li {
    background-color: rgba(255, 255, 255, 0.30);
}
.your-class.mobile-app-slider .slick-dots li.slick-active {
    background-color: #fff;
}
.your-class.mobile-app-slider .slick-list {
    margin: 30px 0
}

.your-class.mobile-app-slider .slick-prev.slick-disabled, .your-class.mobile-app-slider .slick-next.slick-disabled {
    opacity: 0.1;
}

.quick-glance-app-section {
    position: relative;
}

.sky-blue-container {
    height: 920px;
}

.sky-blue-container h3 {
    color: #2B2B2B;
    font-size: 70px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: -1px;
}

.sky-blue-container h3 span {
    color: #007ED3;
}

.sky-blue-container a {
    width: 224px;
    margin: 0 5px;
}

.sky-blue-container a img {
    width: 100%;
}
.sky-blue-container .container {
    height: 100%;
}
.sky-blue-container .container .row {
    height: 100%;
}
.tab-mobile-container img{
    max-width: 100%;
}

.connect-form {
    background-color: #fff;
    box-shadow: 0px 16px 72px 0px rgba(6, 28, 61, 0.08);
    border-radius: 24px;
    padding: 50px;
    min-height: 380px;
    display: flex;
}
.connect-form #submitBtn {
    display: flex;
}
.connect-form .loader {
    padding: 15px;
    left: 20px;
    top: -20px;
}
#success-msg {
    margin: auto;
}
.connect-form label {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    color: #061C3D;
}

.connect-form input, .connect-form textarea {
    border-radius: 5px;
    border: 1px solid var(--Gray-100, #E6E8EC); 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    color: #838E9E;
}

.connect-form a{
    border-radius: 7px;
    background-color: #0B63E5;
    text-transform: unset !important;
    border-color: #0B63E5;
    height: 40px;
}
.connect-form a:hover {
    background-color: #009CDE;
    border-color: #009CDE;
    color: #fff
}

.connect-form a img {
    margin-left: 6px;
    width: 20px;
}

.text-container {
    display: flex;
    flex-direction: column;
}
.text-container h1{
    color: #007ED3;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.75px;
    margin-bottom: 5px;
}
.text-container h2{
    color: #072B4A;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 65.25px;
    letter-spacing: -1.125px;
}
.features-section .new-text-container h2 {
    color: #007ED3;
    font-size: 27px;
    font-style: normal;
    font-weight: 600;
    line-height: 36.75px;
    margin-bottom: 5px;
}
.features-section .new-text-container h3 {
    color: #072B4A;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 65.25px;
    letter-spacing: -1.125px;
}
.text-container p{
    margin: 0;
    color: #6B7280;
    font-size: 27px;
    font-style: normal;
    font-weight: 400;
    line-height: 36.75px;
}
.text-container a {
    color: #007ED3;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #007ED3;
    display: inline-block;
    width: 200px;
    margin-top: 30px;
    text-align: center;
}
.text-container a i {
    margin: 0 4px;
}
.text-container a:hover {
    background-color: #007ED3;
    color: #fff;
}
.mobile-img-first-section .mobile-right-container {
    display: flex;
    justify-content: flex-end;
}
.mobile-img-first-section .mobile-left-container {
    display: flex;
    justify-content: flex-start;
}
.mobile-img-first-section .mobile-right-container img, .mobile-img-first-section .mobile-left-container img {
    max-width: 100%;
}
.mobile-img-first-section .mobile-right-container img {
    margin-right: -20px;
}
.mobile-img-first-section .mobile-left-container img {
    margin-left: -30px;
}
.features-section {
    width: 100%;
}
.features-section {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: top;
    padding: 175px 0 !important;
}


.heading-what-we-offer > h1 {
    color: #007ED3;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 65.25px;
    letter-spacing: -1.125px;
    margin-bottom: 30px;
}
.text-container {
    padding-top: 40px;
}
.join-us-section h4 {
    font-size: 36px;
    font-weight: 700;
    color: #072B4A;
    max-width: 780px;
    margin: auto;
    line-height: 65.25px;
    letter-spacing: -1.125px;
}
.join-us-section h4 span {
    font-weight: 500;
}
.join-us-section h4 span.blue-text {
    font-weight: 700;
    color: #007ED3;
}
.join-us-section img {
    max-width: 100%;
    margin: 100px auto;
}
.testimonial-section h4 {
    font-size: 48px;
    font-weight: 700;
    color: #072B4A;
    max-width: 780px;
    margin: auto;
    line-height: 65.25px;
    letter-spacing: -1.125px; 
    margin-bottom: 50px;
}
.testimonial-section h4 span {
    font-weight: 500;
}
.testimonial-section h4 span.blue-text {
    font-weight: 700;
    color: #007ED3;
}

.testimonial-section .color-boxes {
    border-radius: 15px;
    border: 1px solid #B9E6FE;
    background-color: #fff;
}
.benefit-section {
    /* background-image: url(../images/family_budgeting_new/pricing-bg.webp); */
    background-size: cover;
    width: 100%;
}
.benefit-section-card {
    padding: 20px 20px;
    border-radius: 15px;
    border: 1.5px solid var#B9E6FE;
    background: #fff;
    transition: 0.3s box-shadow linear;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.benefit-section-card .icon-block {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #E0F3FF;
    border-radius: 10px;
    margin-bottom: 20px;
}
.benefit-section-card .icon-block img {
    width: 20px;
}
.benefit-section-card:hover {
   box-shadow: 0px 3px 34.875px 0px rgba(0, 0, 0, 0.13);
}
.benefit-section-card h4 {
    color: #061C3D;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
}
.benefit-section-card h3 {
    color: #061C3D;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 15px;
}
.benefit-section-card span {
    color: #42526B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.75px;
    flex: 1;
    margin-bottom: 15px;
}
.benefit-section-card a {
    color: #007ED3;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    margin-top: auto;
    cursor: default;
}
.benefit-section-card-container {
    row-gap: 30px;
}
.benefit-section .main-heading {
    margin-bottom: 80px;
}
.benefit-section .main-heading h2 {
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: 65.25px;
    letter-spacing: -1.125px;
    margin-bottom: 15px;
}
.benefit-section .main-heading span {
    color: #072B4A;
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 48.75px;
    letter-spacing: -1.125px;
}
.managing-bill-section {
    /* background-image: url(../images/family_budgeting_new/pricing-bg.webp); */
    background-size: cover;
    margin-top: 122px;
    overflow: unset;
    padding: 60px 0 !important;
}
.managing-bill-img-container {
    position: relative;
} 
.managing-bill-img-container img {
    position: relative;
    top: 110px;
    max-width: 100%;
    right: -20px;
}
.managing-bill-text-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.managing-bill-text-container h2, .managing-bill-text-container h1 {
    color: #072B4A;
    font-size: 56.25px;
    font-style: normal;
    font-weight: 700;
    line-height: 71.25px;
    letter-spacing: -1.125px;
}
.managing-bill-text-container p {
    color: #4B5563;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27.75px;
}
.managing-bill-text-container p span {
    color: #374151;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 27.75px;
}
.managing-bill-text-container .photo-frame {
    display: flex;
    align-items: center;
    gap: 20px;
}
.managing-bill-text-container .photo-frame img {
    max-width: 100%;
}
.managing-bill-text-container .photo-frame h4 {
    color: #007ED3;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0;
}
.managing-bill-text-container .photo-frame h4 span {
    font-weight: 700;
}
.managing-bill-text-container .social-icons {
    margin-top: 30px;
}
.managing-bill-text-container .social-icons a > img{
    width: 160px;
}
.managing-bill-text-container .social-icons a {
    margin-right: 10px;
}
.managing-bill-text-container .social-icons a ul {
    list-style: none;
    padding: 0;
    margin: 20px 0 0;
}
.managing-bill-text-container .social-icons a ul li {
    display: flex;
    align-items: center;
    gap: 3px;
}
.managing-bill-text-container .social-icons a ul li img {
    width: 100px;
}
.managing-bill-text-container .social-icons a ul li span {
    color: #222D39;
    font-size: 13.5px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    position: relative;
    top: 2px;
}
.rating-boxes-section {
    /* background-image: url(../images/family_budgeting_new/rating-card-wave.webp); */
    background-size: cover;
    position: relative;
    margin-top: 100px;
}
.rating-boxes-section .rating-boxes-card {
    border-radius: 12px;
    border: 1px solid #E0F3FF;
    background: #FFF;
    box-shadow: 0px 9px 44.85px 0px rgba(0, 0, 0, 0.09);
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 196px;
    position: relative;
}
.rating-boxes-section .rating-boxes-card .top-icon-notch {
    width: 54px;
    height: 54px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007ED3;;
    position: absolute;
    top: -27px;
}
.rating-boxes-section .rating-boxes-card .top-icon-notch img {
    width: 24px;
}
.rating-boxes-section .rating-boxes-card h2 {
    color: #072B4A;
    font-size: 27px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.297px;
    text-transform: uppercase;
    text-align: center;
    margin: 0;
 }
 .rating-boxes-section .rating-boxes-card span {
    color: #007ED3;
    text-align: center;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
    letter-spacing: -0.165px;
    text-transform: uppercase;
 }
 .timely-bill-app-section {
    overflow: hidden;
    /* background-image: url(../images/family_budgeting_new/CTA.webp); */
 }
 .download-white-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 9px;
    border: 1px solid #B9E6FE;
    background: #FFF;
    box-shadow: 0px 9px 24px 0px rgba(6, 28, 61, 0.04);
    padding: 20px;
 }
 .download-white-box h4 {
    margin: 0;
    color: #072B4A;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 65.25px;
    letter-spacing: -1.125px;
 }
 .download-white-box .social-icons {
    margin-top: 5px;
 }
 .button-section {
    display: flex;
    margin-top: 60px;
    justify-content: center;
 }
 .button-section a {
    color: #007ED3;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 18px;
    border-radius: 5px;
    padding: 10px 20px;
    border: 1px solid #007ED3;
    display: inline-block;
    width: 200px;
    margin-top: 30px;
    text-align: center;
 }
 .button-section a:hover {
    background-color: #007ED3;
    color: #fff;
 }
 .button-section a i {
    margin: 0 4px;
 }
 .rating-boxes-section h6 {
    color: #072B4A;
    font-size: 64px;
    font-style: normal;
    font-weight: 600;
    line-height: 87px;
    letter-spacing: -1.5px;
    margin-bottom: 80px;
 }
 #success-msg h4 {
    color: #2e7d32;
    font-size: 16px;
    margin: 0;
    font-weight: 500;
    line-height: 30px;
 }
 .header-button .theme-btn:hover {
    background: #009CDE !important;
 }

.page-main-heading h1 {
    font-weight: 700;
    font-size: 64px;
    line-height: 76.16px;
    color: #2B2B2B;
    margin-bottom: 0;
}
.page-main-heading span {
    color: #007ED2;
    font-weight: 700;
    font-size: 64px;
    line-height: 76.16px;
}
.new-blue {
    color: #007ED2 !important;
}
.black-text {
    color: #2B2B2B !important;
}
.page-main-heading p {
    font-size: 24px;
    margin: 0;
    line-height: 30px;
    margin-top: 10px;
}
.main-header-new {
    margin-bottom: 30px;
    overflow: visible;
}
.features-designed-section {
    position: relative;
    z-index: 1;
}
.shift-container {
    margin-top: -60px;
}
.screenshot-img-container {
    width: 100%;
    position: relative;
    z-index: 1;
}
.screenshot-img-container > div {
    padding: 0 !important;
}
.screenshot-img-container img{
   width: 100%;
} 

.features-designed-section .design-main-heading {
    color: #2B2B2B;
    text-align: center;
    font-size: 48px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 40px;
}
.features-designed-section .designed-cards {
    display: flex;
    flex-direction: column;
    width: 47%;
    margin-bottom: 100px;
}
.features-designed-section .designed-cards .design-box{
    border-radius: 28.336px;
    background: #E0F3FF;
    padding: 35px 65px 0px 65px;
    overflow: hidden;
    padding-top: 20px;
    display: flex;
    height: 400px;
}
.features-designed-section .designed-cards .design-box img {
    max-width: 100%;
    margin: auto;
    display: block;
    height: 100%;
}
.features-designed-section .designed-cards h2 {
    margin: 30px 0 15px;
    color: #072B4A;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
    line-height: 52px;
    letter-spacing: -1.5px
}
.features-designed-section .designed-cards p {
    margin-bottom: 0;
    color: #6B7280;
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 49px;
}
.design-box-rows {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.margin-0px-bottom {
    margin-bottom: 0 !important;
}
.no-bg-img {
    background: none;
}
.borders {
    border-radius: 15px;
    border: 1px solid #B9E6FE;
    background: #fff;
}
.borders .icon-block {
    width: 84px;
    height: 84px;
    border-radius: 50%;
    background: #EFF8FF !important;
}
.borders .icon-block img {
    width: 50px;
}
.borders:hover {
    box-shadow: none;
}
.more-height {
    height: 730px;
}
.low-width {
    width: 100% !important;
    height: 680px;
}
.diff-margin-b {
    margin-bottom: 52px;
}
.design-main-subheading {
    color:#4B5563;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 100px;
    padding-top: 10px;
}
.h-100 {
    height: 100% !important;
}

.blue-section {
    background: #DBF1FF;
    padding: 40px 40px 0;
    display: flex;
    height: 990px;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    position: relative;
}
.blue-section .heading-text h4{
    color: #2B2B2B;
    text-align: center;
    font-size: 55px;
    font-style: normal;
    font-weight: 400;
}
.blue-section .heading-text h4 strong {
    color: #007ED2;
}
.blue-section .cards-container {
    margin: auto;
    display: flex;
    justify-content: center;
}
.blue-section .cards-container .ellipsis-img-container {
    width: 700px;
    height: 634px;
    border-radius: 700px;
    position: absolute;
    /* background-image: url(./../images/family_budgeting_new/family-ellipse-photo.jpeg); */
    background-repeat: no-repeat;
    background-size: 100%;
    bottom: -170px;
    left: 0;
    right: 0;
    margin: auto;
}
.ellipsis-border {
    width: 900px;
    height: 900px;
    border-radius: 634px;
    border: 420px solid rgba(0, 126, 210, 0.02);
    position: absolute;
    bottom: -300px;
    left: 0;
    right: 0;
    margin: auto;
}
.card-layout {
    position: relative;
    min-width: 380px;
    max-width: 380px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4.42px 18.3px 0px rgba(0, 0, 0, 0.06);
    padding: 26px;
    border-radius: 27px;
    top: 40px;
    align-items: flex-start;
    border: 7px solid rgba(200, 235, 254, 0.40);
    left: 330px;
}
.card-layout .card-img {
    height: 80px;
    border-radius: 18px;
    background: #F0F9FF;
    width: 80px;
    justify-content: center;
    display: flex;
    align-items: center;

}
.card-layout .card-img img {
    width: 60px;
    height: 60px;
}
.card-layout h4 {
    color: #061C3D;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin: 0;
    padding: 15px 0;
}
.card-layout p {
    color: #42526B;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 22.099px;
    margin: 0;
}
.card-top-right {
    left: 445px;
}
.card-bottom-left {
    left: -632px;
    top: 430px;
}
.card-bottom-right {
    left: -120px;
    top: 430px;
}
@media (max-width: 991px) {
    .design-main-subheading {
        font-size: 24px;
    }
    .mobile-img-first-section .mobile-right-container {
        margin-top: 40px;
    }
    .family-budget-right-block img.top-img {
        height: auto;
        position: absolute;
        top: -20px;
        width: 200px;
        left: -110px;
    }
    .family-budget-right-block img.bottom-img {
        height: 190px;
        position: absolute;
        bottom: -80px;
        width: 340px;
        left: -150px;
        max-width: fit-content;
    }
    .color-boxes-container .col-md-6 {
        flex-basis: inherit;
        max-width: 100%;
    }
    .your-class .branding-bg {
        width: 48%;
        margin-right: 0;
    }
    .family-budget-section {
        padding-top: 0px !important;
        margin-top: 0;
    }
    .family-budget-heading-block h2 {
        font-size: 36px;
        margin-bottom: 12px;
        text-align: center;
    }
    .family-budget-heading-block > span, .family-budget-heading-block > p {
        font-size: 16px;
        margin-bottom: 10px;
        text-align: center;
    }
    .family-budget-right-block {
        width: 240px;
        height: 240px;
        margin: 40px auto 0;
        position: relative;
        margin-left: 100px;
    }
    .money-manage-section .money-manage-right-block h2 {
        font-size: 36px;
        width: 100%;
    }
    .money-manage-section .money-manage-right-block p {
        font-size: 16px;
    }
    .money-manage-section .padding-70px-top {
        padding-top: 10px;
    }
    .family-budget-right-block img {
        height: 200px;
    }
    .your-class .slick-arrow {
        display: none !important;
    }
    .connect-form {
        padding: 20px;
        margin-top: 20px;
    }
    .sky-blue-container h3 {
        font-size: 36px;
    }
    .sky-blue-container  {
        height: auto;
    }
    .sky-blue-container a {
        width: auto;
    }
    .tab-mobile-container {
        margin-top: 50px;
    }
    .quick-glance-app-section {
        padding-top: 60px !important
    }
    .accordion-style-03 .panel .panel-heading i {
        top: 27px;
    }
    .accordion-budgeting.accordion-style-03 .panel .panel-heading i {
        right: 6px;
    }
    .accordion-style-03 .panel .panel-heading {
        padding: 15px 20px 15px 20px;
    }
    .family-budget-heading-block {
        display: flex;
        flex-direction: column;
    }
    .family-budget-heading-block a {
        width: 230px;
        margin: 30px auto 0;
    }
    .managing-bill-section {
        margin-top: 0;
        padding: 20px 10px !important
    }
    .managing-bill-text-container h2, .managing-bill-text-container h1 {
        font-size: 30px;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
    }
    .managing-bill-text-container p {
        font-size: 16px;
    }
    .managing-bill-text-container p span {
        font-size: 16px;
    }
    .managing-bill-text-container .photo-frame h4 {
        font-size: 16px;
    }
    .managing-bill-img-container {
        margin-top: 30px;
    }
    .managing-bill-img-container img {
        display: block;
        margin: auto;
        position: static;
    }
    .rating-boxes-section {
        margin-top: 20px;
    }
    .rating-boxes-section .rating-boxes-card {
        margin-bottom: 50px;
    }
    .features-section {
        margin-top: -60px !important;
    }
    .mobile-img-first-section .mobile-left-container img {
        margin-left: 0;
    }
    .mobile-img-first-section .mobile-right-container img {
        margin-right: 0;
    }
    .features-section > h1 {
        font-size: 36px;
        margin-bottom: 60px;
    }
    .text-container h2 {
        font-size: 30px;
        line-height: normal;
    }
    .mobile-img-first-section {
        margin-bottom: 60px;
        
    }
    .mobile-img-first-section:nth-child(even) {
        flex-direction: column-reverse;
    }
    .join-us-section h4 {
        font-size: 30px;
        line-height: 40px;
    }
    .benefit-section .main-heading h2 {
        font-size: 32px;
    }
    .benefit-section .main-heading span {
        font-size: 30px;
    }
    .testimonial-section h4 {
        font-size: 36px;
    }
    .download-white-box h4 {
        font-size: 30px;
        line-height: normal;
    }
    .text-container p {
        font-size: 24px;
    }
    .rating-boxes-section h6 {
        font-size: 42px;
        line-height: normal;
     }
     .features-section .new-text-container h3 {
        font-size: 30px;
        line-height: normal;
     }
}

@media (min-width: 768px) {
    .mobile-img-first-section:nth-child(even) {
        flex-direction: row !important;
    }
    .connect-form {
        padding: 20px !important
    }
}


.breadcrumbs-container {
    margin-top: 30px;
    padding-bottom: 60px;
}
.breadcrumbs-container ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    column-gap: 20px;
}
.breadcrumbs-container ul li a {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #007ED3;
}
.breadcrumbs-container ul li span {
    color: #404345;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}
 
.budget-img-container {
    margin-top: 50px;
}
.budget-img-container img {
    width: 100%;
    max-width: 400px;
}
.budget-img-container {
    background-color: #CCEBF8;
    padding: 50px;
}
.budget-container-main-heading h2 {
    color: #2B2B2B;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 44.2px;
    margin: 0;
    margin-top: 20px;
}

.table-of-content-container {
    position: relative;
    padding: 30px 0 !important;
}
.table-of-content-container .table-of-content {
    display: flex;
    flex-direction: column;
}
.table-of-content-container .table-of-content h4 {
    color: #111;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 33px;
    letter-spacing: 0.05px;
    margin: 0;
    margin-bottom: 8px;
}
.table-of-content-container .table-of-content ul {
    padding: 0;
    margin: 0;
    list-style: none;
    padding-left: 20px;
}
.table-of-content-container .table-of-content ul li {
    border-left: 1px solid #D4D4D4;
    padding: 6px 15px;
}
.table-of-content-container .table-of-content ul li a {
    color: #444444;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 19.2px;
    letter-spacing: 0.05px;
}
.table-of-content-container .table-of-content ul li.active a {
    color: #007ED3;
}
.table-of-content-container .table-content-right-section .question-heading p{
    color: #111;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.05px;
    padding: 0 12px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section {
    display: flex;
    flex-direction: column;
    padding: 0 12px 50px;
}

.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-text-block {
    align-items: center;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-text-block span {
    width: 36px;
    height: 36px;
    min-width: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--Gradient-for-gold, linear-gradient(180deg, #FFD504 0%, #FF9800 100%));
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-text-block span img {
    width: 24px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-text-block h4 {
    margin: 0;
    color: #2B2B2B;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.05px;
    margin-left: 15px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section p {
    color: #007ED3;
    font-size: 18px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.05px;
    padding: 10px 0;
    margin-bottom: 15px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section h6 {
    color: #007ED3;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.05px;
    margin: 0;
    padding-bottom: 30px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ul {
    margin: 0;
    padding: 0;
    padding-left: 30px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ul li {
    display: flex;
    padding-bottom: 10px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ul li .list-circle{
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    letter-spacing: -0.094px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ul li span {
    color: #111;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.05px;
    padding-left: 15px;
    position: relative;
    top: 2px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ul li span .text-new-blue {
    font-weight: 600;
    display: inline;
}
.green-circle {
    background-color: rgba(17, 199, 170, 0.1);
    color: #11C7AA;
}
.blue-circle {
    background-color: rgba(0, 126, 211,0.1);
    color: #007ED3;
}
.green-box-header h2 {
    padding: 20px 12px;
    margin: 0;
    background: rgba(200, 255, 246, 0.5);
    color: #2B2B2B;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.2px;
    letter-spacing: 0.05px;
    margin-bottom: 10px;
}

.note-blue-text-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 1px solid #007ED3;
    background: #E8F6FF;
    color: #111;
    font-size: 18px;
    font-style: italic;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.05px;
    padding: 30px;
    text-align: center;
}
.note-green-text-container {
    border: 1px solid #11C7AA;
    background: rgba(240, 253, 244, 0.69);
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ol {
    margin: 0;
    list-style: none;
    padding: 0;
    padding-left: 30px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ol + ul {
    padding-left: 60px;
    margin-top: 40px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ol li {
    display: flex;
    padding-bottom: 10px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ol li .dot-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    min-width: 8px;
    position: relative;
    top: 9px;
}
.green-dot {
    background-color: #11C7AA;
}
.blue-dot {
    background-color: #007ED3;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section ol li span {
    color: #2B2B2B;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.05px;
    padding-left: 10px;
}

.group-img-container {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}
.group-img-container img {
    max-width: 100%;
    margin: auto;
}
.group-img-container.justify-content-center img {
    margin: inherit;
}
.mt-n2 {
    margin-top: -30px;
}
.no-padding-left {
    padding-left: 0;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section h5 {
    color: #2B2B2B;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 31.2px;
    letter-spacing: 0.05px;
}

.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-blue-text {
    align-items: center;
    flex-wrap: wrap;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-blue-text span {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #DEF2FF;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-blue-text span img {
    width: 24px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-blue-text h4 {
    color: #007ED3;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: 27px;
    letter-spacing: 0.05px;
    margin: 0;
    margin-left: 15px;
}
.table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-blue-text p {
    color: #2B2B2B;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0.05px;
    margin: 0;
    padding-left: 51px;
    width: 100%;
}

ol.margin-40px-left {
    margin-left: 40px !important;
}
ul.margin-20px-left {
    margin-left: 20px !important;
}
.padding-0px-bottom {
    padding-bottom: 0 !important;
}

.tracking-all-headings {
    font-size: 24px !important;
}

.big-size-circle {
    width: 48px !important;
    height: 48px !important;
}
.big-size-circle img {
    width: 30px !important;
}

.box-style {
    border-radius: 12px;
    border: 1px solid #E5E7EB;
    background: #fff;
    padding: 30px 20px !important;
    margin-left: 50px !important;
}
.box-style .text-new-blue {
    font-weight: 600 !important;
}
.box-style.no-margin-left {
    margin-left: 0px !important;
}
.block-heading {
    color: #2B2B2B;
    font-size: 20px;
    font-style: italic;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.05px;
    margin-bottom: 30px;
}

.box-image-style {
    padding-bottom: 0 !important;
}

.arrow-down {
    display: flex;
    padding: 20px 0;
    justify-content: center;
}

.budget-finance-text-container h6 {
    color: #2B2B2B;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 44.2px;
    margin-bottom: 0;
}
.budget-finance-text-container h6 p {
    font-weight: 400;
}
.secure-img-container {
    margin-top: 50px;
}
.secure-img-container img {
    max-width: 100%;
    width: 100%;
}
.padding-left-40px {
    padding-left: 40px !important;
}
.all-step-text {
    font-size: 16px !important;
    padding-bottom: 15px !important;
}
.green-para-text {
    color: #11C7AA !important;
    font-size: 18px !important;
}
.black-blue-text-combination {
    margin-top: 40px;
    margin-bottom: 0px;
}
.black-blue-text-combination h1 {
    color: #2B2B2B;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 31.2px;
    letter-spacing: 0.05px;
}
.black-blue-text-combination h3 {
    color: #007ED3;
    font-family: Roboto;
    font-size: 18px;
    font-style: italic;
    font-weight: 500;
    line-height: 27px;
    letter-spacing: 0.05px;
}
.black-blue-text-combination h3.text-black {
    color: #2B2B2B;
    font-style: normal;
}
.fixed-height img {
    width: 300px;
    height: auto;
}
.no-italic {
    font-style: normal !important;
    padding: 20px !important;
}
@media (max-width: 767px) {
    
    .managing-bill-text-container {
        align-items: center;
    }
    .your-class .branding-bg {
        margin-right: 0;
        width: 100%;
    }
    .family-budget-right-block img.bottom-img, .family-budget-right-block img.top-img {
        left: 0;
    }
    .family-budget-right-block img.top-img {
        width: 100%;
        left: -117px;
        top: -52px;
        height: 130px;
    }
    .family-budget-right-block img.bottom-img {
        height: 130px;
        width: auto;
        left: -120px;
        top: 100px;
    }
    .process-step-style-05 .process-step-icon-box .process-step-bfr {
        display: none !important;
    }
    .screenshot-saver-section p {
        font-size: 24px !important;
        max-width: inherit !important;
    }
    .design-main-dessc {
        font-size: 24px !important;
    }
    .screenshot-saver-section h2 {
        font-size: 36px !important;
    }
    .screenshot-saver-section::after {
        width: 300px !important;
        height: 300px !important;
    }
    .breadcrumbs-container {
        margin-top: 0;
        padding-bottom: 30px;
        padding-top: 30px;
    }
    .budget-container-main-heading h2 {
        font-size: 36px;
    }
    .table-of-content {
        margin-bottom: 40px;
    }
    .green-box-header h2 {
        font-size: 24px;
    }
    .table-of-content-container .table-content-right-section .question-heading p {
        font-size: 16px;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-text-block h4 {
        font-size: 24px;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section p {
        font-size: 16px !important;
        padding-left: 0 !important;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section ul {
        padding-left: 0;
        margin-left: 0 !important;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section ul li span {
        font-size: 16px;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section ol {
        padding-left: 0;
        margin-left: 0 !important;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section ol + ul {
        padding-left: 0;
    }
    .note-blue-text-container {
        padding: 15px;
        font-size: 14px;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section h5 {
        font-size: 24px;
    }
    .table-of-content-container .table-content-right-section .question-heading .icon-content-section .heading-icon-with-blue-text h4 {
        font-size: 20px;
    }
    .group-img-container {
        flex-direction: column;
    }
    .budget-img-container {
        flex-direction: column;
        padding: 20px;
    }
    .budget-finance-text-container h6 {
        font-size: 24px;
        line-height: normal;
    }
    .group-img-container img:nth-child(2) {
        width: 24px;
        margin: auto;
        transform: rotate(90deg);
    }
    .page-main-heading h1 {
        font-size: 36px;
        line-height: normal;
    }
    .page-main-heading h1 br {
        display: none;
    }
    .page-main-heading span {
        font-size: 36px;
        line-height: normal;
    }
    .page-main-heading p {
        font-size: 16px;
    }
    .main-header-new {
        margin-top: 30px !important;
    }
    .screenshot-img-container {
        width: 100%;
    }
    .shift-container {
        margin-top: 0;
    }
    .features-designed-section .designed-cards {
        width: 100%;
        margin-bottom: 60px !important;
    }
    .features-designed-section .design-main-heading {
        font-size: 36px;
    }
    .design-box-rows {
        padding: 0 20px;
    }
    .features-designed-section .designed-cards .design-box {
        height: inherit;
        padding: 20px 0 0;
    }
    .features-designed-section .designed-cards h2 {
        font-size: 30px;
        line-height: normal;
    }
    .features-designed-section .designed-cards p {
        line-height: 30px;
        font-size: 20px;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
    .process-step-style-05 .process-step-icon-box .process-step-bfr {
        display: none !important;
    }
    .page-main-heading h1 {
        font-size: 50px;
        line-height: normal;
    }
    .page-main-heading span {
        font-size: 50px;
        line-height: normal;
    }
    .page-main-heading p {
        font-size: 20px;
    }
    .features-designed-section .designed-cards {
        margin-bottom: 60px;
    }
    .features-designed-section .designed-cards .design-box {
        padding: 22px 20px 0 20px;
    }
    .features-designed-section .designed-cards h2 {
        font-size: 36px;
        line-height: normal;
    }
    .features-designed-section .designed-cards p {
        font-size: 24px;
    }
    .managing-bill-text-container h2, .managing-bill-text-container h1 {
        text-align: center;
    }
    .managing-bill-text-container {
        align-items: center;
    }
    .testimonial-section .row .col-lg-4 {
        max-width: 80%;
        flex: 0 0  100%;
        margin: auto;
    }
}

.bill-img-bottom {
    top: 0 !important
}
.screenshot-saver-section {
    position: relative;
}
.screenshot-saver-section .row > div {
    padding: 0 !important;
}
.screenshot-saver-section::after {
    content: '';
    position: absolute;
    /* background-image: url(./../images/family_budgeting_new/ellipse-899.png); */
    bottom: 0;
    width: 600px;
    height: 600px;
    background-repeat: no-repeat;
    background-size: contain;
    left: 0px;
}
.screenshot-saver-section h2 {
    margin-bottom: 10px;
    font-size: 48px;
    color: #2B2B2B;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.screenshot-saver-section h2 span {
    color: #007ED2;
}
.screenshot-saver-section p {
    color: #4B5563;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: auto;
    max-width: 756px;
    margin-bottom: 80px;
}

.design-main-dessc {
    color: #4B5563;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 66px;
}

.branding-container {
    align-items: inherit;
    row-gap: 30px;
}

@media (max-width: 1199px) and (min-width: 768px) {
    .card-layout {
        min-width: 300px;
        max-width: 300px;
        padding: 13px;
        top: 150px;
    }
    .card-top-right {
        left: 335px;
    }
    .card-layout h4 {
        font-size: 14px;
    }
    .card-layout p {
        font-size: 12px;
    }
    .card-bottom-left, .card-bottom-right {
        top: 440px;
    }
    .card-bottom-left {
        left: -370px;
    }
    .card-bottom-right {
        left: -230px;
    }
    .ellipsis-border {
        width: 770px;
        height: 770px;
        border: 335px solid rgba(0, 126, 210, 0.02);
    }
}

@media (max-width: 767px) {
    .blue-section .heading-text h4 {
        font-size: 36px;
        line-height: normal;
    }
    .blue-section .cards-container .ellipsis-img-container {
        width: 100%;
        height: 400px;
        bottom: -130px;
    }
    .card-layout {
        min-width: 200px;
        max-width: 200px;
        padding: 10px;
        border-radius: 27px;
        transform: scale(0.7);
        top: 0;
        left: 210px;
    }
    .card-layout h4 {
        font-size: 12px;
        line-height: normal;
        padding: 8px 0;
    }
    .card-layout p {
        font-size: 10px;
    }
    .ellipsis-border {
        width: 100%;
        height: 730px;
        border-radius: 634px;
        border: 210px solid rgba(0, 126, 210, 0.02);
        position: absolute;
        bottom: -300px;
    }
    .blue-section .cards-container .ellipsis-img-container {
        width: 60%;
        height: 540px;
        border-radius: 700px;
        bottom: -390px;
    }
    .card-top-right {
        left: 200px;
    }
    .card-bottom-right {
        left: -189px;
        top: 190px;
    }
    .card-bottom-left {
        left: -212px;
        top: 190px;
    }
    .blue-section {
        height: 640px;
        padding: 40px 20px 0;
    }
}

/* Main Container */
.login-container {
    display: flex;
    height: 100svh;
  }
  
  /* Left Container */
  .login-left-container {
    width: 60%;
    display: flex;
    flex-direction: column;
  }
  
  .login-left-container .login-logo {
    padding: 20px;
    display: flex;
    position: absolute;
  }
  
  .login-left-container .login-logo img {
    width: 144px;
  }
  
  .left-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 400px;
    margin: auto;
  }
  
  .left-content h1 {
    color: #223263;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    letter-spacing: -1.6px;
    margin: 0;
    padding-bottom: 15px;
  }
  
  .left-content p {
    color: #969696;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin: 0;
    padding-bottom: 15px;
  }
  
  /* Right Container */
  .login-right-container {
    width: 40%;
    background-color: #f0f9ff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 60px;
    box-sizing: border-box;
  }
  
  .login-right-container h2 {
    color: #223263;
    text-align: center;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: 129.098%;
    letter-spacing: -0.8px;
    margin: 0;
    padding-bottom: 15px;
  }
  
  .login-right-container p {
    color: #7d94a0;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    margin: 0;
    padding: 20px 0;
  }
  
  .login-right-container img {
    max-width: 100%;
  }
  
  /* Form Inputs */
  .material-input {
    position: relative;
    margin: 20px 0;
  }
  
  .material-input label {
    position: absolute;
    z-index: 1;
    background-color: #fff;
    left: 10px;
    top: 19px;
    pointer-events: none;
    padding: 0 8px;
    transition: all 0.2s ease;
  }
  
  .material-input label.active {
    top: -8px;
    color: #007ed2;
    font-size: 14px;
  }
  
  .material-input input {
    color: #2b2b2b;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    border-radius: 10px;
    border: 1.5px solid #d9d9d9;
    padding: 15px;
    width: 100%;
    outline: none;
    box-sizing: border-box;
  }
  
  .material-input input:focus {
    border-color: #007ed2;
  }
  
  /* Buttons */
  .sign-in-btn {
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.12);
    outline: none;
    border: 0;
    border-radius: 15px;
    color: #fff;
    height: 48px;
    background-color: #007ed2;
    font-size: 16px;
    font-weight: 700;
    width: 100%;
    cursor: pointer;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  
  .sign-in-btn:disabled {
    background-color: #d9d9d9;
    pointer-events: none;
  }
  
  .sign-in-with-google {
    outline: none;
    color: #2b2b2b;
    height: 54px;
    background-color: #fff;
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid #e6e8e7;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .sign-in-with-google img {
    margin: 0 12px;
  }
  
  /* Divider */
  .or-divider {
    display: flex;
    align-items: center;
    color: #6e6e6e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    justify-content: space-between;
    margin: 25px 0;
  }
  
  .or-divider::before,
  .or-divider::after {
    content: '';
    width: 46%;
    background-color: #d9d9d9;
    height: 1px;
  }
  
  /* Help Text Section */
  .grow {
    overflow: hidden;
    transition: height 0.3s ease;
  }
  
  .measuringWrapper {
    border-top: 1px solid #000;
    padding-top: 20px;
  }
  
  .help-text {
    color: #6c6c6c;
    font-size: 14px;
    line-height: 1.5;
  }
  
  .help-text div {
    margin-bottom: 10px;
  }
  
  /* Need an Account Section */
  .need-an-account {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6c6c6c;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    margin-top: 32px;
  }
  
  .need-an-account a {
    text-decoration: none;
    color: #007ed2;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    margin-left: 10px;
  }
  
  .need-an-account a:hover {
    text-decoration: underline;
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media (max-width: 767px) {
    .login-right-container {
      display: none;
    }
  
    .login-left-container {
      width: 100% !important;
    }
  
    .left-content {
      width: 90% !important;
      padding-bottom: 50px;
    }
  
    .login-container .login-left-container .login-logo {
      position: static;
      padding: 20px 0;
      justify-content: center;
    }
  
    .left-content h1 {
      font-size: 34px;
    }
  
    .login-container .login-left-container .login-logo img {
      width: 190px;
    }
  }
  
  .resend-btn {
    font-weight: bold;
    color: blue;
    cursor: pointer;
    margin-top: 20px;
    margin-left: 10px;
  }
  .resend-btn:hover {
    text-decoration: underline;
  }


  .otp-resend {
    margin-top: 20px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: 400;
    padding: 15px;
  }

 
